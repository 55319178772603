html,
body {
    height: 100%;
    width: 100%;
    margin: 0;
}

body {
    font-size: 13px;
    line-height: 20px;
    background-color: #e5e5e5;
}

.linkForgotPassword {
    margin: 0 auto !important;
}

.linkForgotPassword > a {
    text-decoration: none;
}

:global(.message-wrapper) {
    position: relative;
    overflow: hidden;
    width: 100%;
    margin: 11px 0;
    padding: 11px 0;
}

:global(.message-wrapper .circle-wrapper) {
    height: 43px;
    width: 43px;
    border-radius: 50%;
}

:global(.message-wrapper .text-wrapper) {
    padding: 11px;
    min-height: 43px;
    width: 60%;
    margin: 0 11px;
    box-shadow: 0 1px 0 0 rgba(50, 50, 50, 0.3);
    border-radius: 2px;
    font-weight: 300;
    position: relative;
    /* word-break: break-all; */
    opacity: 1;
}

:global(.message-wrapper .text-wrapper:before) {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
}

:global(.message-wrapper.them .circle-wrapper) {
    background: #f44336;
    float: left;
    color: #ffffff;
}

:global(.message-wrapper.them .text-wrapper) {
    background: #f44336;
    float: left;
    color: #ffffff;
}

:global(.message-wrapper.them .text-wrapper:before) {
    border-width: 0 10px 10px 0;
    border-color: transparent #f44336 transparent transparent;
    position: absolute;
    top: 0;
    left: -9px;
}

:global(.message-wrapper.me .circle-wrapper) {
    background: #ff5722;
    float: right;
    color: #333333;
}

:global(.message-wrapper.me .circle-wrapper) {
    background: #ff5722;
    float: right;
    color: #333333;
}

:global(.message-wrapper.me .text-wrapper) {
    background: #ffffff;
}

:global(.message-wrapper.me .text-wrapper:before) {
    border-width: 10px 10px 0 0;
    border-color: #ffffff transparent transparent transparent;
    position: absolute;
    top: 0;
    right: -9px;
}

@tailwind base;

@tailwind components;

@tailwind utilities;
